import { useQuery } from '@apollo/client';
import { useState } from 'react';
import parse from 'html-react-parser';
import 'react-multi-carousel/lib/styles.css';
import LinearProgress from '@mui/material/LinearProgress';
import Pagination from '@mui/material/Pagination';
import { GET_RELATED_CONTENT, GET_STORIES } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';
import CarouselSection from './CarouselSection';
export interface StoryDataInterface {
  date: string;
  stories_title: string;
  stories_content: string;
  stories_image: GUID;
  stories_source: string;
  stories_link: string;
  featured_story: string;
}

export interface GUID {
  guid: string;
}

export interface RelatedContentDataInterface {
  date: string;
  stories_title: string;
  stories_content: string;
  stories_source: string;
  stories_link: string;
}

const News = () => {
  const [displayError, setDisplayError] = useState(false);
  const [relatedLoading, setRelatedLoading] = useState(false);
  const [relatedContentPage, setRelatedContentPage] = useState(1);
  const [relatedContentPageCount, setRelatedContentPageCount] = useState(1);
  const [relatedContentData, setRelatedContentData] =
    useState<RelatedContentDataInterface[]>();
  const [storyPage, setStoryPage] = useState(1);
  const [storyPageCount, setStoryPageCount] = useState(1);
  const [storyData, setStoryData] = useState<StoryDataInterface[]>();
  const [storyDataCurrentPage, setStoryDataCurrentPage] =
    useState<StoryDataInterface[]>();
  const [featuredStoryData, setFeaturedStoryData] =
    useState<StoryDataInterface[]>();

  const paginate = (array: any, page_size: any, page_number: any) => {
    const paginatedData: any = array.slice(
      (page_number - 1) * page_size,
      page_number * page_size
    );
    return paginatedData.sort((a: any, b: any) =>
      a.featured_story !== '1' ? 1 : -1
    );
  };

  const { loading } = useQuery(GET_STORIES, {
    onCompleted: (results: any) => {
      const count: any = Math.ceil(results.getStories.length / 10);
      setStoryPageCount(count === 0 ? 1 : count);
      setStoryData(results.getStories);
      setStoryDataCurrentPage(paginate(results.getStories, 10, 1));
      const newData: any = [];
      results.getStories.forEach((d: any) => {
        if (d.featured_story === '1') {
          newData.push(d);
        }
      });
      setFeaturedStoryData(newData);
    },
    onError: () => {
      setDisplayError(true);
    },
  });

  const { loading: relatedContentLoading, fetchMore } = useQuery(
    GET_RELATED_CONTENT,
    {
      variables: {
        page: 1,
      },
      onCompleted: (results: any) => {
        const count: any = Math.ceil(results.getRelatedContentCount.count / 10);
        setRelatedContentPageCount(count === 0 ? 1 : count);
        setRelatedContentData(results.getRelatedContent);
      },
      onError: () => {
        setDisplayError(true);
      },
    }
  );

  const handleStoryChange = (event: any, value: any) => {
    setStoryPage(value);
    setStoryDataCurrentPage(paginate(storyData, 10, value));
    const element: any = document.getElementById('storiesTop');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const handleRelatedContentChange = (event: any, value: any) => {
    setRelatedLoading(true);
    setRelatedContentPage(value);
    const element: any = document.getElementById('relatedContentTop');
    element.scrollIntoView({ behavior: 'smooth' });
    fetchMore({
      variables: {
        page: value,
      },
      updateQuery: (prevResult: any, { fetchMoreResult }: any) => {
        setRelatedLoading(false);
        return setRelatedContentData(fetchMoreResult.getRelatedContent);
      },
    });
  };

  if (
    loading ||
    relatedContentLoading ||
    !storyData ||
    !relatedContentData ||
    !featuredStoryData ||
    !storyDataCurrentPage
  ) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <h1 className='page-title'>News & Press Releases</h1>
      <section
        className='carousel-section'
        style={{
          margin: '20px 40px 20px 20px',
          borderTop: 'solid 2px gray',
          borderBottom: 'solid 2px gray',
        }}>
        <CarouselSection data={featuredStoryData} />
      </section>
      <div className='np-container'>
        <div id='storiesTop' className='np-left'>
          <h2>Stories</h2>
          {!loading ? (
            storyDataCurrentPage.map((stories: any, index: any) => (
              <div className='np-stories'>
                <div className='np-img'>
                  <img src={stories.stories_image.guid} alt='news article' />
                </div>
                <div className='np-article'>
                  <h4>{stories.stories_title}</h4>
                  {parse(stories.stories_content)}
                  <p className='np-credentials'>
                    {stories.stories_source} -{' '}
                    {new Date(stories.date)
                      .toLocaleDateString()
                      .replace('/', '.')
                      .replace('/', '.')}
                  </p>
                  <a
                    href={stories.stories_link}
                    target='_blank'
                    rel='noopener noreferrer'>
                    Click to read the article
                  </a>
                </div>
              </div>
            ))
          ) : (
            <LinearProgress color='inherit' />
          )}

          <Pagination
            count={storyPageCount}
            page={storyPage}
            onChange={handleStoryChange}
            shape='rounded'
          />
        </div>

        <div id='relatedContentTop' className='np-right'>
          <h2>Related Content</h2>
          <div className='np-related'>
            {!relatedLoading ? (
              relatedContentData.length === 0 ? (
                <div className='np-stories'>Coming Soon! </div>
              ) : (
                relatedContentData.map((content: any, index: any) => (
                  <div className='np-stories'>
                    <div className='np-article'>
                      <h4>{content.related_content_title}</h4>
                      {parse(content.related_content_content)}
                      <p className='np-credentials'>
                        {content.related_content_source} -{' '}
                        {new Date(content.date)
                          .toLocaleDateString()
                          .replace('/', '.')
                          .replace('/', '.')}
                      </p>
                      <a
                        href={content.related_content_link}
                        target='_blank'
                        rel='noopener noreferrer'>
                        Click to read the article
                      </a>
                    </div>
                  </div>
                ))
              )
            ) : (
              <LinearProgress color='inherit' />
            )}
          </div>
          {relatedContentData.length === 0 ? (
            ''
          ) : (
            <Pagination
              count={relatedContentPageCount}
              page={relatedContentPage}
              onChange={handleRelatedContentChange}
              shape='rounded'
            />
          )}
        </div>
      </div>
    </>
  );
};

export default News;
