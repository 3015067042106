import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_TDAAS_PAGE } from '../GraphQLQueries';
import QuickSightDashboard from './QuickSightDashboard';
import ErrorMessage from './ErrorMessage';
import ImageModal from './ImageModal';

const TDaaS = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_TDAAS_PAGE, {
    onError: () => {
      setDisplayError(true);
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div className='breadcrumbs'>
        <Link to='/'>Home</Link> / <Link to='/technology'>Technology</Link> /
        <span>Technology Data as a Service</span>
      </div>
      <div className='tech-container'>
        <h1 className='page-title'>{data.getTDaaSPage[0].tdaas_title}</h1>
      </div>
      <div className='tech-container tech-pad'>
        {parse(data.getTDaaSPage[0].tdaas_content)}
      </div>
      <div className='vid-outer'>
        <div className='vid-wrap'>
          <div className='video-container'>
            <iframe
              width='560'
              height='315'
              src={data.getTDaaSPage[0].tdaas_video_url}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen></iframe>
          </div>
        </div>
      </div>
      <div className='tech-container tech-pad'>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getTDaaSPage[0].tdaas_image.guid}
              alt='graphical display of traffic data'
            />
            <ImageModal src={data.getTDaaSPage[0].tdaas_image.guid} />
          </div>
          <div className='tech-box'>
            {parse(data.getTDaaSPage[0].tdaas_content_2)}
          </div>
        </div>
      </div>

      <div className='tech-container tech-pad'>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getTDaaSPage[0].tdaas_image_2.guid}
              alt='graphical display of traffic data'
            />
            <ImageModal src={data.getTDaaSPage[0].tdaas_image_2.guid} />
          </div>
          <div className='tech-box'>
            {parse(data.getTDaaSPage[0].tdaas_content_3)}
          </div>
        </div>
      </div>

      <div className='tech-container tech-pad'>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getTDaaSPage[0].tdaas_image_3.guid}
              alt='graphical display of traffic data'
            />
            <ImageModal src={data.getTDaaSPage[0].tdaas_image_3.guid} />
          </div>
          <div className='tech-box'>
            {parse(data.getTDaaSPage[0].tdaas_content_4)}
          </div>
        </div>
      </div>

      <div className='tech-container tech-pad'>
        {parse(data.getTDaaSPage[0].tdaas_content_5)}

        <div className='tech-flex-pull tech-pad'>
          <div className='tech-box'>
            {parse(data.getTDaaSPage[0].tdaas_content_6)}
          </div>
          <div className='tech-box'>
            <img
              src={data.getTDaaSPage[0].tdaas_image_4.guid}
              alt='integrated roadways logo'
            />
          </div>
        </div>
      </div>

      <div className='dashboard-container'>
        <QuickSightDashboard />
      </div>
      <div
        className='tdaas-cta'
        style={{
          backgroundImage: `url(${data.getTDaaSPage[0].tdaas_cta_background_image.guid})`,
        }}>
        <h3>{data.getTDaaSPage[0].tdaas_cta_content}</h3>
        <Link className='cta-btn' to='/subscription'>
          {data.getTDaaSPage[0].tdaas_cta_button}
        </Link>
      </div>
    </>
  );
};

export default TDaaS;
