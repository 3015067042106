import { useQuery } from '@apollo/client';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import ContactBanner from './ContactBanner';
import { GET_HOME_PAGE } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';

const Home = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_HOME_PAGE, {
    onError: () => {
      setDisplayError(true)
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div
        className='web-hero'
        style={{
          backgroundImage: `url(${data.getHomePage[0].hero_image.guid})`,
        }}>
        <div className='web-hero-container'>
          <h1>{data.getHomePage[0].hero_title}</h1>
          {parse(data.getHomePage[0].hero_content)}
        </div>
      </div>

      <div className='web-video'>
        <div
          style={{
            padding: '56.25% 0 0 0',
            position: 'relative',
            textAlign: 'center',
          }}>
          <iframe
            title='main-video'
            src={data.getHomePage[0].video}
            style={{
              position: 'absolute',
              top: '0',
              left: '10%',
              width: '80%',
              height: '100%',
            }}
            frameBorder='0'
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen></iframe>
        </div>
      </div>

      <div className='web-container outdated-roads'>
        <div className='or-left'>
          <img
            src={data.getHomePage[0].outdated_image.guid}
            alt='OutDated Roads'
          />
        </div>
        <div className='or-right'>
          <h2>{data.getHomePage[0].outdated_title}</h2>
          {parse(data.getHomePage[0].outdated_content)}
        </div>
      </div>

      <div className='smart-pavement'>
        <div className='web-container'>
          <div className='sp-left'>
            <h2>{data.getHomePage[0].about_title}</h2>
            <img
              src={data.getHomePage[0].about_image.guid}
              alt='Section Cutout'
            />
            {parse(data.getHomePage[0].about_content)}
          </div>
          <div className='sp-right'>
            {parse(data.getHomePage[0].about_content_2)}
          </div>
        </div>
      </div>

      <div className='web-container web-benefits'>
        <div className='wb-left'>
          <h2>{data.getHomePage[0].benefits_title}</h2>
          {parse(data.getHomePage[0].benefits_content)}
        </div>
        <div className='wb-right'>
          <img
            src={data.getHomePage[0].benefits_image.guid}
            alt='SP Benefits'
          />
        </div>
      </div>

      <div className='in-action'>
        <div className='web-container'>
          {data.getProjects.map((project: any, index: any) => (
            <div key={project.project_title}
              className={
                index === 0
                  ? 'ia-left'
                  : index === 1
                    ? 'ia-right'
                    : 'ia-multiple ia-right'
              }>
              <div
                className='ia-header'
                style={{
                  backgroundImage: `url(${project.project_image.guid})`,
                }}>
                <p>{project.project_title}</p>
                <h4>{project.project_subtitle}</h4>
              </div>
              <div className='ia-body'>{parse(project.project_content)}</div>
            </div>
          ))}
        </div>
      </div>

      <ContactBanner />
    </>
  );
};

export default Home;
