import { useQuery } from '@apollo/client';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_TECHNOLOGY_PAGE } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';
import ImageModal from './ImageModal';

const Technology = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_TECHNOLOGY_PAGE, {
    onError: () => {
      setDisplayError(true)
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />
  }

  return (
    <>
      <h1 className='page-title'>{data.getTechnologyPage[0].technology_title}</h1>
      <div className='tech-container'>
        <div className="tech-anchors">
          <h2 id="capabilities">Capabilities</h2>
          <a href="#services">View Services</a> - <a href="#technology">View Technology</a>
          <h3>{data.getTechnologyPage[0].sustainable_title}</h3>
        </div>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].sustainable_image.guid}
              alt='slab'
            />
            <ImageModal src={data.getTechnologyPage[0].sustainable_image.guid} />
          </div>
          <div className='tech-box'>
            {parse(data.getTechnologyPage[0].sustainable_content)}
          </div>
        </div>
        {parse(data.getTechnologyPage[0].sustainable_content_2)}
        <img
          src={data.getTechnologyPage[0].sustainable_image_2.guid}
          alt='revenue stream'
        />
        {parse(data.getTechnologyPage[0].sustainable_content_3)}
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <div className="tech-anchors">
          <h2 id="services">Services</h2>
          <a href="#capabilities">View Capabilities</a> - <a href="#technology">View Technology</a>
          <h3>{data.getTechnologyPage[0].service_overview_title}</h3>
        </div>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].service_overview_image.guid}
              alt='traffic data statistics'
            />
            <ImageModal src={data.getTechnologyPage[0].service_overview_image.guid} />
          </div>
          <div className='tech-box'>
            <h4>{data.getTechnologyPage[0].service_overview_subtitle}</h4>
            {parse(data.getTechnologyPage[0].service_overview_content)}
          </div>
        </div>
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <h2>{data.getTechnologyPage[0].wireless_title}</h2>
        {parse(data.getTechnologyPage[0].wireless_content)}
        <div className='tech-flex-pull'>
          <div className='technology-tech-box'>
            {parse(data.getTechnologyPage[0].wireless_content_2)}
          </div>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].wireless_image.guid}
              alt='wireless technology overview'
            />
            <ImageModal src={data.getTechnologyPage[0].wireless_image.guid} />
          </div>
        </div>
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <h2>{data.getTechnologyPage[0].edge_services_title}</h2>
        {parse(data.getTechnologyPage[0].edge_services_content)}
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].edge_services_image.guid}
              alt='networking technology overview'
            />
            <ImageModal src={data.getTechnologyPage[0].edge_services_image.guid} />
          </div>
          <div className='tech-box'>
            {parse(data.getTechnologyPage[0].edge_services_content_2)}
          </div>
        </div>
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <h2>{data.getTechnologyPage[0].wireless_charging_title}</h2>
        <div className='tech-flex-pull'>
          <div className='technology-tech-box'>
            {parse(data.getTechnologyPage[0].wireless_charging_content)}
          </div>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].wireless_charging_image.guid}
              alt='digital charging overview'
            />
            <ImageModal src={data.getTechnologyPage[0].wireless_charging_image.guid} />
          </div>
        </div>
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <h2>{data.getTechnologyPage[0].assisted_autonomy_title}</h2>
        {parse(data.getTechnologyPage[0].assisted_autonomy_content)}
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <div className="tech-anchors">
          <h2 id="technology">Technology</h2>
          <a href="#capabilities">View Capabilities</a> - <a href="#services">View Services</a>
          <h3>{data.getTechnologyPage[0].digital_infrastructure_title}</h3>
        </div>
        <div className='tech-flex tech-pad'>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].digital_infrastructure_image.guid}
              alt='slab cutout of road with technology revealed'
            />
            <ImageModal src={data.getTechnologyPage[0].digital_infrastructure_image.guid} />
          </div>
          <div className='tech-box'>
            {parse(data.getTechnologyPage[0].digital_infrastructure_content)}
          </div>
        </div>
        <div className='tech-flex-pull tech-pad'>
          <div className='tech-box'>
            {parse(data.getTechnologyPage[0].digital_infrastructure_content_2)}
          </div>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].digital_infrastructure_image_2.guid}
              alt='graphic of digital infrastructure'
            />
            <ImageModal src={data.getTechnologyPage[0].digital_infrastructure_image_2.guid} />
          </div>
        </div>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].digital_infrastructure_image_3.guid}
              alt='charging overview'
            />
            <ImageModal src={data.getTechnologyPage[0].digital_infrastructure_image_3.guid} />
          </div>
          <div className='tech-box'>
            {parse(data.getTechnologyPage[0].digital_infrastructure_content_3)}
          </div>
        </div>
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <h2>{data.getTechnologyPage[0].modular_title}</h2>
        {parse(data.getTechnologyPage[0].modular_content)}
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].modular_image.guid}
              alt='cutout of road showing how technology is integrated'
            />
            <ImageModal src={data.getTechnologyPage[0].modular_image.guid} />
          </div>
          <div className='tech-box'>
            <h4>{data.getTechnologyPage[0].modular_subtitle}</h4>
            {parse(data.getTechnologyPage[0].modular_content_2)}
          </div>
        </div>
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <h2>{data.getTechnologyPage[0].control_centers_title}</h2>
        <div className='tech-flex-pull'>
          <div className='technology-tech-box'>
            {parse(data.getTechnologyPage[0].control_centers_content)}
          </div>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].control_centers_image.guid}
              alt='data center for controlling technology '
            />
            <ImageModal src={data.getTechnologyPage[0].control_centers_image.guid} />
          </div>
        </div>
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <h2>{data.getTechnologyPage[0].in_motion_title}</h2>
        {parse(data.getTechnologyPage[0].in_motion_content)}
        <div className='tech-flex tech-pad'>
          <div className='tech-box'>
            <img
              src={data.getTechnologyPage[0].in_motion_image.guid}
              alt='cutout of road showing how technology is integrated'
            />
            <ImageModal src={data.getTechnologyPage[0].in_motion_image.guid} />
          </div>
          <div className='tech-box'>
            {parse(data.getTechnologyPage[0].in_motion_content_2)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Technology;
