import { useQuery } from '@apollo/client';
import { useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_STAFF } from '../GraphQLQueries';
import Chart from './Chart';
import ErrorMessage from './ErrorMessage';
import './Chart.css';

const OurTeam = () => {
  const [displayError, setDisplayError] = useState(false);
  const { loading } = useQuery(GET_STAFF, {
    onError: () => {
      setDisplayError(true)
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div className='tech-container tech-pad'>
        <h1 className='page-title'>Our Team</h1>
      </div>
      <div className='tech-container'>
        <div className='teams'>
          <ul>
            <li>
              <span>
                <Box bgcolor={'darkslateblue'} width='15px' height='15px'></Box>
              </span>
              <span>Management Team</span>
            </li>
            <li>
              <span>
                <Box bgcolor={'gold'} width='15px' height='15px'></Box>
              </span>
              <span>Financial Team</span>
            </li>
            <li>
              <span>
                <Box bgcolor={'steelblue'} width='15px' height='15px'></Box>
              </span>
              <span>Project Management Team</span>
            </li>
            <li>
              <span>
                <Box
                  bgcolor={'mediumvioletred'}
                  width='15px'
                  height='15px'></Box>
              </span>
              <span>Engineering & Development Team</span>
            </li>
            <li>
              <span>
                <Box bgcolor={'orange'} width='15px' height='15px'></Box>
              </span>
              <span>Business Development Team</span>
            </li>
          </ul>
        </div>
      </div>
      <Chart />
    </>
  );
};

export default OurTeam;
