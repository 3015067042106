import { gql } from '@apollo/client';

export const GET_DASHBOARD_URL = gql`
  query Quicksight {
    dashboardEmbedUrl {
      EmbedUrl
    }
  }
`;

export const GET_HOME_PAGE = gql`
  query getHomePage {
    getHomePage {
      hero_title
      hero_image {
        guid
      }
      hero_content
      video
      outdated_image {
        guid
      }
      outdated_title
      outdated_content
      about_title
      about_image {
        guid
      }
      about_content
      about_content_2
      benefits_title
      benefits_image {
        guid
      }
      benefits_content
    }
    getProjects {
      project_title
      project_subtitle
      project_content
      project_image {
        guid
      }
    }
  }
`;

export const GET_CONTACT_PAGE = gql`
  query getContactPage {
    getContactPage {
      contact_title
      contact_content
    }
  }
`;

export const GET_PRIVACY_PAGE = gql`
  query getPrivacyPage {
    getPrivacyPage {
      privacy_title
      modified
      privacy_content
    }
  }
`;

export const GET_STAFF = gql`
  query getStaff {
    getStaff {
      staff_name
      staff_email
      staff_title
      staff_bio
      staff_image {
        guid
      }
      staff_team_colors
    }
  }
`;

export const GET_TECHNOLOGY_PAGE = gql`
  query getTechnologyPage {
    getTechnologyPage {
      technology_title
      sustainable_title
      sustainable_image {
        guid
      }
      sustainable_content
      sustainable_content_2
      sustainable_image_2 {
        guid
      }
      sustainable_content_3
      service_overview_title
      service_overview_subtitle
      service_overview_image {
        guid
      }
      service_overview_content
      wireless_title
      wireless_image {
        guid
      }
      wireless_content
      wireless_content_2
      edge_services_title
      edge_services_image {
        guid
      }
      edge_services_content
      edge_services_content_2
      wireless_charging_title
      wireless_charging_image {
        guid
      }
      wireless_charging_content
      assisted_autonomy_title
      assisted_autonomy_content
      digital_infrastructure_title
      digital_infrastructure_image {
        guid
      }
      digital_infrastructure_content
      digital_infrastructure_content_2
      digital_infrastructure_image_2 {
        guid
      }
      digital_infrastructure_content_3
      digital_infrastructure_image_3 {
        guid
      }
      modular_title
      modular_content
      modular_image {
        guid
      }
      modular_subtitle
      modular_content_2
      control_centers_title
      control_centers_image {
        guid
      }
      control_centers_content
      in_motion_title
      in_motion_content
      in_motion_image {
        guid
      }
      in_motion_content_2
    }
  }
`;

export const GET_TDAAS_PAGE = gql`
  query getTDaaSPage {
    getTDaaSPage {
      tdaas_title
      tdaas_content
      tdaas_video_url
      tdaas_image {
        guid
      }
      tdaas_content_2
      tdaas_image_2 {
        guid
      }
      tdaas_content_3
      tdaas_image_3 {
        guid
      }
      tdaas_content_4
      tdaas_content_5
      tdaas_content_6
      tdaas_image_4 {
        guid
      }
      tdaas_cta_content
      tdaas_cta_button
      tdaas_cta_background_image {
        guid
      }
    }
  }
`;

export const GET_WIRELESS_NETWORKING_PAGE = gql`
  query getWirelessNetworkingPage {
    getWirelessNetworkingPage {
      wireless_networking_title
      wireless_networking_content
      wireless_networking_image {
        guid
      }
    }
  }
`;

export const GET_EDGE_SERVICES_PAGE = gql`
  query getEdgeServicesPage {
    getEdgeServicesPage {
      edge_services_title
      edge_services_content
      edge_services_subtitle
      edge_services_image {
        guid
      }
      edge_services_content_2
      edge_services_subtitle_2
      edge_services_content_3
      edge_services_subtitle_3
      edge_services_image_2 {
        guid
      }
      edge_services_content_4
      edge_services_subtitle_4
      edge_services_content_5
    }
  }
`;

export const GET_SMART_PAVEMENT_PAGE = gql`
  query getSmartPavementPage {
    getSmartPavementPage {
      smart_pavement_title
      smart_pavement_subtitle
      smart_pavement_content
      smart_pavement_subtitle_2
      smart_pavement_image {
        guid
      }
      smart_pavement_content_2
      smart_pavement_subtitle_3
      smart_pavement_content_3
      smart_pavement_subtitle_4
      smart_pavement_content_4
      smart_pavement_subtitle_5
      smart_pavement_content_5
      smart_pavement_subtitle_6
      smart_pavement_content_6
      smart_pavement_subtitle_7
      smart_pavement_image_2 {
        guid
      }
      smart_pavement_content_7
    }
  }
`;

export const GET_WIRELESS_CHARGING_PAGE = gql`
  query getWirelessChargingPage {
    getWirelessChargingPage {
      wireless_charging_title
      wireless_charging_content
      wireless_charging_image {
        guid
      }
      wireless_charging_content_2
    }
  }
`;

export const GET_ASSISTED_AUTONOMY_PAGE = gql`
  query getAssistedAutonomyPage {
    getAssistedAutonomyPage {
      assisted_autonomy_title
      assisted_autonomy_content
    }
  }
`;

export const GET_ORG_CHART = gql`
  query getOrgChart {
    getOrgChart {
      organizational_chart_data
    }
  }
`;

export const GET_SUBSCRIPTION_PLAN_PAGE = gql`
  query getSubscriptionPlanPage {
    getSubscriptionPlanPage {
      subscription_plan_title
      subscription_plan_title_2
      subscription_plan_content
      subscription_plan_content_2
      subscription_plan_button
    }
  }
`;

export const GET_STORIES = gql`
  query getStories {
    getStories {
      date
      stories_title
      stories_content
      stories_image {
        guid
      }
      stories_source
      stories_link
      featured_story
    }
  }
`;

export const GET_RELATED_CONTENT = gql`
  query getRelatedContent($page: Int) {
    getRelatedContent(page: $page) {
      date
      related_content_title
      related_content_content
      related_content_source
      related_content_link
    }
    getRelatedContentCount {
      count
    }
  }
`;
