import parse from 'html-react-parser';
import { useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { ContactForm } from './ContactForm';
import { GET_CONTACT_PAGE } from '../GraphQLQueries';

const Contact = () => {
  const { data, loading } = useQuery(GET_CONTACT_PAGE, {});
  if (loading) {
    return <LinearProgress color='inherit' />;
  }
  return (
    <>
      <h1 className='page-title'>{data.getContactPage[0].contact_title}</h1>
      <div className='web-contact'>
        <div className='web-container'>
          {parse(data.getContactPage[0].contact_content)}
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Contact;
