import { useRef, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import InputField from './InputField';
import SelectBox from './SelectBox';
import TextArea from './TextArea';
import { useMutation } from '@apollo/client';
import { SEND_CONTACT_US_EMAIL } from '../GraphQLMutations';
import Alert from '@mui/material/Alert';
import './ContactForm.css';

export const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [formSubmissionError, setFormSubmissionError] =
    useState<boolean>(false);

  const [sendContactUsEmail] = useMutation(SEND_CONTACT_US_EMAIL, {
    context: { clientName: 'api' },
    onCompleted: (data) => {
      setDisableButton(false);
      setFormSubmitted(true);
      formikRef.current?.resetForm();
      formikRef.current?.setSubmitting(false);
    },
    onError: (err) => {
      setDisableButton(false);
      setFormSubmissionError(true);
      formikRef.current?.setSubmitting(false);
    },
  });

  const formikRef = useRef<FormikProps<Record<string, unknown>>>(null);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          email: '',
          category: 'Consumer',
          message: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(35, 'Must be 35 characters or less')
            .required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          category: Yup.string().oneOf(
            [
              'Consumer',
              'Transportation/DOT',
              'Business Partner',
              'Investor',
              'Other',
            ],
            'Invalid Job Type'
          ),
          message: Yup.string()
            .max(300, 'Must be 300 characters or less')
            .required('Required'),
        })}
        onSubmit={(values) => {
          setDisableButton(true);
          sendContactUsEmail({
            variables: { form: values },
          });
        }}>
        {(formik) => {
          return (
            <Form>
              {formSubmitted && (
                <Alert severity='success'>
                  Thank you for reaching out! We will follow up with you
                  shortly.
                </Alert>
              )}
              {formSubmissionError && (
                <Alert severity='error'>
                  There was a problem submitting the form. Please try again
                  later.
                </Alert>
              )}

              <InputField
                label='Name'
                name='name'
                type='text'
                placeholder='Your name...'
              />

              <InputField
                label='Email Address'
                name='email'
                type='email'
                placeholder='Your email address...'
              />

              <SelectBox
                label='Category that best describes your interest'
                name='category'>
                <option value='Consumer'>Consumer</option>
                <option value='Transportation/DOT'>Transportation/DOT</option>
                <option value='Business Partner'>Business Partner</option>
                <option value='Investor'>Investor</option>
                <option value='Other'>Other</option>
              </SelectBox>

              <TextArea
                label='Message'
                name='message'
                placeholder='Write something...'
                style={{ height: '200px' }}
              />

              <input
                type='submit'
                value='Submit'
                disabled={!formik.isValid || disableButton}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
