import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_DASHBOARD_URL } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';

const QuickSightDashboard = () => {
  const [displayError, setDisplayError] = useState(false);
  const { loading, data } = useQuery(GET_DASHBOARD_URL, {
    context: { clientName: 'api' },
    fetchPolicy: 'network-only',
    onError: () => {
      setDisplayError(true)
    },
  });


  const dashboardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!loading) {
      const options = {
        url: data.dashboardEmbedUrl.EmbedUrl,
        container: dashboardRef.current,
        scrolling: 'no',
        height: 'AutoFit',
        loadingHeight: '500px',
        width: '90%',
        locale: 'en-US',
        footerPaddingEnabled: true,
      };
      QuickSightEmbedding.embedDashboard(options);
    }
  }, [data, loading]);

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />
  }

  return (
    <>
      <div>
        <div ref={dashboardRef} />
      </div>
    </>
  );
};

export default QuickSightDashboard;
