import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_SMART_PAVEMENT_PAGE } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';
import ImageModal from './ImageModal';

const SmartPavement = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_SMART_PAVEMENT_PAGE, {
    onError: () => {
      setDisplayError(true);
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div className='breadcrumbs'>
        <Link to='/'>Home</Link> / <Link to='/technology'>Technology</Link> /
        <span>Smart Pavement</span>
      </div>
      <div className='tech-container tech-pad'>
        <h1 className='page-title'>
          {data.getSmartPavementPage[0].smart_pavement_title}
        </h1>
      </div>
      <div className='tech-container'>
        <h2>{data.getSmartPavementPage[0].smart_pavement_subtitle}</h2>
        {parse(data.getSmartPavementPage[0].smart_pavement_content)}
      </div>
      <div className='tech-divider'></div>
      <div className='tech-container'>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getSmartPavementPage[0].smart_pavement_image.guid}
              alt='construction showing installation of smart pavement slab'
            />
            <ImageModal
              src={data.getSmartPavementPage[0].smart_pavement_image.guid}
            />
          </div>
          <div className='tech-box'>
            <h2>{data.getSmartPavementPage[0].smart_pavement_subtitle_2}</h2>
            {parse(data.getSmartPavementPage[0].smart_pavement_content_2)}
          </div>
        </div>
      </div>

      <div className='tech-divider'></div>

      <div className='tech-container'>
        <h2>{data.getSmartPavementPage[0].smart_pavement_subtitle_3}</h2>
        {parse(data.getSmartPavementPage[0].smart_pavement_content_3)}
      </div>

      <div className='tech-divider'></div>

      <div className='tech-container'>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getSmartPavementPage[0].smart_pavement_image_2.guid}
              alt='cutout showing inner workings of leveling system'
            />
            <ImageModal
              src={data.getSmartPavementPage[0].smart_pavement_image_2.guid}
            />
          </div>
          <div className='tech-box'>
            <h2>{data.getSmartPavementPage[0].smart_pavement_subtitle_4}</h2>
            {parse(data.getSmartPavementPage[0].smart_pavement_content_4)}
          </div>
        </div>
      </div>

      <div className='tech-divider'></div>

      <div className='tech-container'>
        <h2>{data.getSmartPavementPage[0].smart_pavement_subtitle_5}</h2>
        {parse(data.getSmartPavementPage[0].smart_pavement_content_5)}
      </div>

      <div className='tech-divider'></div>

      <div className='tech-container'>
        <h2>{data.getSmartPavementPage[0].smart_pavement_subtitle_6}</h2>
        {parse(data.getSmartPavementPage[0].smart_pavement_content_6)}
      </div>

      <div className='tech-divider'></div>
      <div className='smart-pavement-tech-container tech-container'>
        <h2>{data.getSmartPavementPage[0].smart_pavement_subtitle_7}</h2>
        {parse(data.getSmartPavementPage[0].smart_pavement_content_7)}
      </div>
    </>
  );
};

export default SmartPavement;
