import { useField } from 'formik';

const InputField = ({ label, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <input {...field} {...props} className={meta.touched && meta.error ? 'text-input input-error' : 'text-input'} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};

export default InputField;