import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '100%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    p: 4,
    outline: 0,
};

const ImageModal = (props: any) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <div className="img-enlarge"><button onClick={handleOpen}>Click to enlarge image</button></div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        className='close-button top-right'
                        onClick={handleClose}>
                        X
                    </div>
                    <img
                        src={props.src}
                        alt='real time data collection graphic'
                    />
                </Box>
            </Modal>
        </>
    );
};

export default ImageModal;
