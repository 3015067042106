import { Alert } from '@mui/material';

const ErrorMessage = () => {
    return (
        <>
            <Alert
                style={{
                    margin: '0 auto',
                    marginTop: '40px',
                    textAlign: 'center',
                }}
                severity='error'>
                {
                    'An error has occurred. Please try again later. If the issue persists, please contact us.'
                }
                <br />
                <a
                    style={{ color: 'inherit' }}
                    href='mailto:admin@integratedroadways.com'>
                    Contact Support Here
                </a>
            </Alert>
        </>
    );
};

export default ErrorMessage;
