import { useField } from 'formik';

const SelectBox = ({ label, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
        <div>
            <label htmlFor={props.id || props.name}>{label}</label>
            <select {...field} {...props} className={meta.touched && meta.error ? 'text-input input-error' : 'text-input'} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};


export default SelectBox;