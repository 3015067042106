import { Route, Routes } from 'react-router-dom';
import AssistedAutonomy from './components/AssistedAutonomy';
import Contact from './components/Contact';
import EdgeServices from './components/EdgeServices';
import Home from './components/Home';
import Privacy from './components/Privacy';
import OurTeam from './components/OurTeam';
import SmartPavement from './components/SmartPavement';
import TDaaS from './components/TDaas';
import Technology from './components/Technology';
import WirelessCharging from './components/WirelessCharging';
import WirelessNetworking from './components/WirelessNetworking';
import SubscriptionPlan from './components/SubscriptionPlan';
import News from './components/News';

const Routing = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/our-team' element={<OurTeam />} />
      <Route path='/subscription' element={<SubscriptionPlan />} />
      <Route path='/technology' element={<Technology />} />
      <Route path='/news' element={<News />} />
      <Route path='/technology/tdaas' element={<TDaaS />} />
      <Route
        path='/technology/wireless-networking'
        element={<WirelessNetworking />}
      />
      <Route path='/technology/edge-services' element={<EdgeServices />} />
      <Route path='/technology/smart-pavement' element={<SmartPavement />} />
      <Route
        path='/technology/wireless-charging'
        element={<WirelessCharging />}
      />
      <Route
        path='/technology/assisted-autonomy'
        element={<AssistedAutonomy />}
      />
    </Routes>
  );
};

export default Routing;
