import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_ASSISTED_AUTONOMY_PAGE } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';

const AssistedAutonomy = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_ASSISTED_AUTONOMY_PAGE, {
    onError: () => {
      setDisplayError(true);
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div className='breadcrumbs'>
        <Link to='/'>Home</Link> / <Link to='/technology'>Technology</Link> /
        <span> Assisted Autonomy</span>
      </div>
      <div className='tech-container tech-pad'>
        <h1 className='page-title'>
          {data.getAssistedAutonomyPage[0].assisted_autonomy_title}
        </h1>
      </div>
      <div className='tech-container tech-pad'>
        {parse(data.getAssistedAutonomyPage[0].assisted_autonomy_content)}
      </div>
    </>
  );
};

export default AssistedAutonomy;
