import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_EDGE_SERVICES_PAGE } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';
import ImageModal from './ImageModal';

const EdgeServices = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_EDGE_SERVICES_PAGE, {
    onError: () => {
      setDisplayError(true);
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div className='breadcrumbs'>
        <Link to='/'>Home</Link> / <Link to='/technology'>Technology</Link> /
        <span> Edge Services</span>
      </div>
      <div className='tech-container tech-pad'>
        <h1 className='page-title'>
          {data.getEdgeServicesPage[0].edge_services_title}
        </h1>
      </div>
      <div className='tech-container'>
        {parse(data.getEdgeServicesPage[0].edge_services_content)}
      </div>
      <div className='tech-divider'></div>

      <div className='tech-container'>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getEdgeServicesPage[0].edge_services_image.guid}
              alt='real time data collection graphic'
            />
            <ImageModal
              src={data.getEdgeServicesPage[0].edge_services_image.guid}
            />
          </div>
          <div className='tech-box'>
            <h2>{data.getEdgeServicesPage[0].edge_services_subtitle}</h2>
            {parse(data.getEdgeServicesPage[0].edge_services_content_2)}
          </div>
        </div>
      </div>

      <div className='tech-divider'></div>

      <div className='tech-container'>
        <h2>{data.getEdgeServicesPage[0].edge_services_subtitle_2}</h2>
        {parse(data.getEdgeServicesPage[0].edge_services_content_3)}
      </div>

      <div className='tech-divider'></div>

      <div className='tech-container'>
        <div className='tech-flex'>
          <div className='tech-box'>
            <img
              src={data.getEdgeServicesPage[0].edge_services_image_2.guid}
              alt='road intersection displaying interconnect devices'
            />
            <ImageModal
              src={data.getEdgeServicesPage[0].edge_services_image_2.guid}
            />
          </div>
          <div className='tech-box'>
            <h2>{data.getEdgeServicesPage[0].edge_services_subtitle_3}</h2>
            {parse(data.getEdgeServicesPage[0].edge_services_content_4)}
          </div>
        </div>
      </div>

      <div className='tech-divider'></div>

      <div className='tech-container tech-pad'>
        <h2>{data.getEdgeServicesPage[0].edge_services_subtitle_4}</h2>
        {parse(data.getEdgeServicesPage[0].edge_services_content_5)}
      </div>
    </>
  );
};

export default EdgeServices;
