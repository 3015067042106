import { Link } from "react-router-dom";

const ContactBanner = () => {

    return (
        <div className='web-cta'>
            <p>Ready to learn more?</p>
            <Link to="/contact">
                <button className='white-outline-btn'>Contact Us!</button>
            </Link>
        </div>
    );
};

export default ContactBanner;