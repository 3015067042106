import { useQuery } from '@apollo/client';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_SUBSCRIPTION_PLAN_PAGE } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';

const SubscriptionPlan = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_SUBSCRIPTION_PLAN_PAGE, {
    onError: () => {
      setDisplayError(true);
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div className='container'>
        <div className='squeeze-box'>
          <h1 className='page-title'>
            {data.getSubscriptionPlanPage[0].subscription_plan_title}
            <br></br>
            {data.getSubscriptionPlanPage[0].subscription_plan_title_2}
          </h1>
          <div className='accent-line'></div>
          {parse(data.getSubscriptionPlanPage[0].subscription_plan_content)}
          <div className='squeeze-features'>
            {parse(data.getSubscriptionPlanPage[0].subscription_plan_content_2)}
          </div>
          <p className='squeeze-button'>
            <a
              className='blue-btn'
              href='https://portal.integratedroadways.com/?page=signup'>
              {data.getSubscriptionPlanPage[0].subscription_plan_button}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlan;
