import Box from '@mui/material/Box';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const CarouselSection = (props: any) => {
  return (
    <Carousel
      swipeable={true}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      ssr={false}
      partialVisbile
      removeArrowOnDeviceType={['mobile']}
      itemClass='carousel-item'
      responsive={responsive}>
      {props.data.map((stories: any) => {
        return (
          <Box
            className='stories-box'
            sx={{
              backgroundColor: 'primary.white',
              borderRight: 'solid 2px gray',
            }}>
            <h3 className='stories-title'>{stories.stories_title}</h3>
            {parse(stories.stories_content)}
            <div className='source-date-container'>
              <span className='np-credentials'>
                {stories.stories_source} -{' '}
                {new Date(stories.date)
                  .toLocaleDateString()
                  .replace('/', '.')
                  .replace('/', '.')}
              </span>
              <span>
                <a
                  className='stories-link'
                  href={stories.stories_link}
                  target='_blank'
                  rel='noopener noreferrer'>
                  Click to read the article
                </a>
              </span>
            </div>
          </Box>
        );
      })}
    </Carousel>
  );
};

export default CarouselSection;
