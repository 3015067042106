import { Link } from 'react-router-dom';

const Footer = () => {

    return (
        <footer>
            <div className="footer-wrapper">
                <nav className="foot_nav">
                    <Link to='/'>Home</Link>
                    <Link to='/contact'>Contact</Link>
                    <Link to='/privacy'>Privacy</Link>
                </nav>
                <p>Integrated Roadways, LLC // 1712 Main St. Ste. 2007 // Kansas City, MO 64108</p>
            </div>
        </footer>
    );
};

export default Footer;
