import configJson from "./config.json";

export function getConfig() {
  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    user_name: configJson.user_name,
    password: configJson.password,
    api: {
      origin: configJson.api.origin,
      contentOrigin: configJson.api.contentOrigin,
      audience: configJson.api.audience
        ? configJson.api.audience
        : configJson.api.origin,
    },
  };
}
