import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_ORG_CHART, GET_STAFF } from '../GraphQLQueries';
import LinearProgress from '@mui/material/LinearProgress';
import Card from './Card';
import ErrorMessage from './ErrorMessage';
import './Chart.css';

const Chart = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_ORG_CHART, {
    onError: () => {
      setDisplayError(true)
    },
  });

  const { data: staffData, loading: staffLoading } = useQuery(GET_STAFF, {
    onError: () => {
      setDisplayError(true)
    },
  });

  if (loading || staffLoading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />
  }

  const newData: any = data.getOrgChart[0].organizational_chart_data
    .replace('<p>', '')
    .replace('</p>', '');

  return (
    <div className='org-tree'>
      <Card data={JSON.parse(newData)} staffData={staffData.getStaff} />
    </div>
  );
};

export default Chart;
