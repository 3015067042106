// import './Header.css';
import { Link } from 'react-router-dom';
import logo from '../images/ir-logo-header.png';

const Header = () => {
  return (
    <header>
      <nav className='navbar'>
        <div className='brand-title'>
          <img src={logo} alt='Integrated Roadways' />
        </div>
        <a href='#top' className='toggle-button'>
          <span className='bar'></span>
          <span className='bar'></span>
          <span className='bar'></span>
        </a>
        <div className='navbar-links'>
          <ul>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link id='our-team-link' to='/our-team'>
                Our Team
              </Link>
            </li>
            <li>
              <div className='dropdown'>
                <Link id='technology-link' className='dropbtn' to='/technology'>
                  Technology
                </Link>
                <div className='dropdown-content'>
                  <Link id='tdaas-link' to='/technology/tdaas'>
                    Traffic Data as a Service
                  </Link>
                  <Link
                    id='wireless-networking-link'
                    to='/technology/wireless-networking'>
                    Wireless Networking
                  </Link>
                  <Link id='edge-services-link' to='/technology/edge-services'>
                    Edge Services
                  </Link>
                  <Link
                    id='wireless-charging-link'
                    to='/technology/wireless-charging'>
                    Wireless EV Charging
                  </Link>
                  <Link
                    id='assisted-autonomy-link'
                    to='/technology/assisted-autonomy'>
                    Assisted Autonomy
                  </Link>
                  <Link
                    id='smart-pavement-link'
                    to='/technology/smart-pavement'>
                    Smart Pavement
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <Link to='/news'>News</Link>
            </li>
            <li>
              <Link id='contact-link' to='/contact'>
                Contact Us
              </Link>
            </li>
            <li>
              <a href='https://portal.integratedroadways.com/?page=login'>
                Login
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
