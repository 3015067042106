import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import * as Auth0 from 'auth0-js';

import { getConfig } from './config';

const AuthorizedApolloProvider = ({ children }: any) => {
  const config = getConfig();

  let auth0: any = new Auth0.WebAuth({
    domain: config.domain,
    clientID: config.clientId,
  });

  const originHttpLink = createHttpLink({
    uri: config.api.origin,
  });

  const contentOriginHttpLink = createHttpLink({
    uri: config.api.contentOrigin,
  });

  const authLink = setContext(
    (request) =>
      new Promise((success, fail) => {
        auth0.client.login(
          {
            realm: 'Username-Password-Authentication',
            username: config.user_name,
            password: config.password,
            audience: config.api.audience,
          },
          function (err: any, authResult: any) {
            success({
              headers: {
                Authorization: `Bearer ${authResult.accessToken}`,
              },
            });
            if (err) {
              console.log(err);
              fail(err);
            }
          }
        );
      })
  );

  const apolloClient = new ApolloClient({
    link: ApolloLink.split(
      operation => operation.getContext().clientName === "api",
      authLink.concat(originHttpLink),
      contentOriginHttpLink,
    ),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
