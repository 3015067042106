import { useField } from 'formik';

const TextArea = ({ label, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
        <div>
            <label htmlFor='{props.id || props.name }'>{label}</label><br />
            <textarea className={meta.touched && meta.error ? 'text-area input-error' : 'text-area'} {...field} {...props}></textarea>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    )
}

export default TextArea;