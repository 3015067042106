import { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import parse from 'html-react-parser';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './Chart.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'white',
  border: '2px solid #000',
  p: 4,
  outline: 0,
};

const Card = (props: any) => {
  const [open, setOpen] = useState(false);
  const [modalName, setModalName] = useState('')
  const handleOpen = (name: any) => {
    return () => {
      setModalName(name)
      setOpen(true);
    }
  }
  const handleClose = () => setOpen(false);

  return (
    <ul>
      {props.data.map((item: any) => (
        <Fragment key={item.name}>
          <li>
            <div className='card'>
              <div className='card-body'>
                <h4>{item.name}</h4>
                <p>
                  {props.staffData
                    ? props.staffData.find(
                      (x: any) => x.staff_name === item.name
                    ).staff_title
                    : ''}
                </p>
                {props.staffData ? (
                  <a
                    className='org-chart-email'
                    href={`mailto: ${props.staffData.find(
                      (x: any) => x.staff_name === item.name
                    ).staff_email
                      }`}>
                    Email
                  </a>
                ) : (
                  ''
                )}
                <span className='org-chart-bio' onClick={handleOpen(item.name)}>
                  Bio
                </span>
                <div className='colors'>
                  {props.staffData
                    ? props.staffData
                      .find((x: any) => x.staff_name === item.name)
                      .staff_team_colors.map((item: any) => (
                        <span>
                          <Box
                            bgcolor={item}
                            width='20px'
                            height='10px'></Box>
                        </span>
                      ))
                    : ''}
                </div>
              </div>
              <div
                className='card-footer'
                style={{ background: item.colors }}></div>
              <div></div>
            </div>
            {item.children?.length && (
              <Card data={item.children} staffData={props.staffData} />
            )}
          </li>
        </Fragment>
      ))}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>

        <Box sx={style}>
          <div className='staff-container'>
            <img
              className={'staff-image'}
              src={
                props.staffData && modalName
                  ? props.staffData.find(
                    (x: any) => x.staff_name === modalName
                  ).staff_image.guid
                  : ''
              }
              alt='staff'
            />
            <div className='staff-info'>
              <div>
                {props.staffData && modalName
                  ? props.staffData.find(
                    (x: any) => x.staff_name === modalName
                  ).staff_name
                  : ''}</div>
              <div>
                {props.staffData && modalName
                  ? props.staffData.find(
                    (x: any) => x.staff_name === modalName
                  ).staff_title
                  : ''}
              </div>
              {props.staffData && modalName ? (
                <a
                  href={`mailto:${props.staffData.find(
                    (x: any) => x.staff_name === modalName
                  ).staff_email
                    } `}>
                  {
                    props.staffData.find(
                      (x: any) => x.staff_name === modalName
                    ).staff_email
                  }
                </a>
              ) : (
                ''
              )}
            </div>
            <div
              className='close-button top-right'
              onClick={handleClose}>
              X
            </div>
          </div>

          <div className='org-divider'></div>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            {props.staffData && modalName
              ? parse(
                props.staffData.find(
                  (x: any) => x.staff_name === modalName
                ).staff_bio
              )
              : ''}
          </Typography>
        </Box>
      </Modal>
    </ul>
  );
};

export default Card;
