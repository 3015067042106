import { useQuery } from '@apollo/client';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_PRIVACY_PAGE } from '../GraphQLQueries';
import ContactBanner from './ContactBanner';
import ErrorMessage from './ErrorMessage';

const Privacy = () => {
  const [displayError, setDisplayError] = useState(false);
  const [modifiedDate, setModifiedDate] = useState('');
  const { data, loading } = useQuery(GET_PRIVACY_PAGE, {
    onCompleted: (result) => {
      const date: any = new Date(result.getPrivacyPage[0].modified);
      setModifiedDate(
        date.toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      );
    },
    onError: () => {
      setDisplayError(true)
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />
  }

  return (
    <>
      <h1 className='page-title'>{data.getPrivacyPage[0].privacy_title}</h1>
      <h4 className='sub-title'>Last updated: {modifiedDate}</h4>
      <div className='privacy-policy'>
        {parse(data.getPrivacyPage[0].privacy_content)}
      </div>

      <ContactBanner />
    </>
  );
};

export default Privacy;
