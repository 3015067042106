import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import parse from 'html-react-parser';
import LinearProgress from '@mui/material/LinearProgress';
import { GET_WIRELESS_NETWORKING_PAGE } from '../GraphQLQueries';
import ErrorMessage from './ErrorMessage';
import ImageModal from './ImageModal';

const WirelessNetworking = () => {
  const [displayError, setDisplayError] = useState(false);
  const { data, loading } = useQuery(GET_WIRELESS_NETWORKING_PAGE, {
    onError: () => {
      setDisplayError(true);
    },
  });

  if (loading) {
    return <LinearProgress color='inherit' />;
  }

  if (displayError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div className='breadcrumbs'>
        <Link to='/'>Home</Link> / <Link to='/technology'>Technology</Link> /
        <span>Wireless Networking</span>
      </div>
      <div className='tech-container'>
        <h1 className=' page-title'>
          {data.getWirelessNetworkingPage[0].wireless_networking_title}
        </h1>
      </div>
      <div className='tech-container tech-pad'>
        <div className='tech-flex-pull'>
          <div className='tech-box'>
            {parse(
              data.getWirelessNetworkingPage[0].wireless_networking_content
            )}
          </div>
          <div className='tech-box'>
            <img
              src={
                data.getWirelessNetworkingPage[0].wireless_networking_image.guid
              }
              alt='graphical display of traffic data'
            />
            <ImageModal
              src={
                data.getWirelessNetworkingPage[0].wireless_networking_image.guid
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WirelessNetworking;
